.page-content-item[data-v-8aa5bd42] {
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.page-content-item .title[data-v-8aa5bd42] {
  color: #323232;
}
.page-content-item .content[data-v-8aa5bd42] {
  color: #999999;
}
.el-form-item[data-v-8aa5bd42] {
  margin-bottom: 10px;
}
.page-nav-title[data-v-8aa5bd42] {
  height: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-left: 4px #50B1B2 solid;
}
.page-nav-title h3[data-v-8aa5bd42] {
  margin-left: 10px;
}
.page-treatment-options[data-v-8aa5bd42] {
  background-color: #F7FAFA;
  border: 1px #50B1B2 solid;
  padding: 16px;
  border-radius: 8px;
}
.page-goods-title[data-v-8aa5bd42] {
  height: 28px;
  background-color: #FAFAFA;
  color: #50B1B2;
  padding-left: 20px;
  font-size: 14px;
  margin-top: 10px;
}
.page-user-icon[data-v-8aa5bd42] {
  width: 48px;
  height: 48px;
  border-radius: 24px;
}
.page-user-type[data-v-8aa5bd42] {
  height: 21px;
  padding: 0px 4px;
  font-size: 12px;
  color: white;
  border-radius: 4px;
}
.page-user-name-type span[data-v-8aa5bd42] {
  color: #909090;
  font-size: 14px;
  margin-left: 12px;
}
.page-user-send-content[data-v-8aa5bd42] {
  background-color: #F0F2F2;
  border-radius: 2px 12px 12px 12px;
  padding: 12px;
  margin: 12px 12px 12px 0px;
}