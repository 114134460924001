.change-time-dialog-wrap-part-box .dialog-content-part-wrap-box .dialog-chang-date-item-box[data-v-aa850bc8] {
  width: 300px;
  min-height: 46px;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #e5e5e5;
}
.change-time-dialog-wrap-part-box .dialog-content-part-wrap-box .dialog-chang-date-item-box.active[data-v-aa850bc8] {
  height: 260px;
  border-color: #50b1b2;
}
.change-time-dialog-wrap-part-box .dialog-content-part-wrap-box .dialog-chang-date-item-box .chang-date-popover-btn[data-v-aa850bc8] {
  height: 46px;
  padding: 12px 20px;
  box-sizing: border-box;
  cursor: pointer;
}
.change-time-dialog-wrap-part-box .dialog-content-part-wrap-box .dialog-chang-date-item-box .chang-date-popover-btn .iamges-date[data-v-aa850bc8] {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}
.change-time-dialog-wrap-part-box .dialog-content-part-wrap-box .dialog-chang-date-item-box .date-item-selected-box .date-selected-image-box[data-v-aa850bc8] {
  margin-top: 10px;
}
.change-time-dialog-wrap-part-box .dialog-content-part-wrap-box .dialog-chang-date-item-box .date-item-selected-box .date-selected-image-box .iamges-origin[data-v-aa850bc8] {
  width: 138px;
  height: 171px;
  border-radius: 8px 8px 8px 8px;
}
.change-time-dialog-wrap-part-box[data-v-aa850bc8] .el-dialog__wrapper {
  position: fixed !important;
}
.change-time-dialog-wrap-part-box[data-v-aa850bc8] .el-dialog__wrapper .el-dialog {
  min-height: auto;
  border-radius: 8px;
  margin: 0 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
.change-time-dialog-wrap-part-box[data-v-aa850bc8] .el-dialog__wrapper .el-dialog .el-dialog__header {
  border: none;
  border-radius: 8px;
  padding: 20px 30px 10px;
}
.change-time-dialog-wrap-part-box[data-v-aa850bc8] .el-dialog__wrapper .el-dialog .el-dialog__header .el-dialog__headerbtn {
  right: 30px;
}
.change-time-dialog-wrap-part-box[data-v-aa850bc8] .el-dialog__wrapper .el-dialog .el-dialog__body {
  min-height: 260px;
  padding: 20px 60px 10px;
}
.change-time-dialog-wrap-part-box[data-v-aa850bc8] .el-dialog__wrapper .el-dialog .el-dialog__footer {
  padding: 20px 60px 60px;
}
.change-time-dialog-wrap-part-box[data-v-aa850bc8] .el-dialog__wrapper .el-dialog .el-dialog__footer .el-button {
  font-size: 16px;
  padding: 8px 44px;
}