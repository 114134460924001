.dialog-chang-date-popover {
  max-height: 274px;
  padding: 0px;
  overflow-y: scroll;
}
.dialog-chang-date-popover .chang-date-popover-scrollbar .el-scrollbar__wrap {
  width: 100%;
}
.dialog-chang-date-popover .chang-date-popover-scrollbar .el-scrollbar__view {
  width: 100%;
  padding: 6px 0px;
}
.dialog-chang-date-popover .chang-date-popover-scrollbar .el-scrollbar__view .chang-date-popover-item {
  height: 34px;
  line-height: 34px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  color: #606266;
}
.dialog-chang-date-popover .chang-date-popover-scrollbar .el-scrollbar__view .chang-date-popover-item:hover {
  background-color: #f5f7fa;
}
.dialog-chang-date-popover .chang-date-popover-scrollbar .el-scrollbar__view .chang-date-popover-item.selected {
  color: #50b1b2;
  font-weight: 700;
}